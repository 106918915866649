
/*#region Global Variables*/
$primary-dark: #0d0d0d; 
$primary-grey: #333333; 
$primary-lightgrey: #4d4d4d;   
$primary-light: #f2f2f2;
$primary-red: #800000;

@font-face {
  font-family: "Snell Roundhand, Cursive";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/SnellRoundhand.woff"); /* IE9 Compat Modes */
  src: local("Snell Roundhand"), local("SnellRoundhand"),
    url("../../assets/SnellRoundhand.woff") format("embedded-opentype"),
    ///* IE6-IE8 */ url("../fonts/barlow-condensed-v4-latin/barlow-condensed-v4-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/SnellRoundhand.woff")
      format("woff"),
    ///* Modern Browsers */ url("../fonts/barlow-condensed-v4-latin/barlow-condensed-v4-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */
    //  url("../fonts/barlow-condensed-v4-latin/barlow-condensed-v4-latin-300.svg#BarlowCondensed") format("svg"); /* Legacy iOS */
}

$header: copperplate;
$primary: Verdana, Geneva, Tahoma, sans-serif;
$snell: Snell Roundhand, Cursive;

$desktop-to-mobile: "screen and (max-width: 900px)"; //  use this if your starting from desktop and working your way in   
$mobile-to-desktop: "screen and (min-width: 900px)"; //  use this if you started from mobile and working your way out

// Example
@media #{desktop-to-mobile} {   // <--- 1. what this says is 'when it reaches max-width: 900px'
  .class-or-#id-goes-here {     // <--- 2. you either want to target a className or id 
    background: red;          // <--- 3. and apply these new styles 
  }                             // * KEEP IN MIND this is only the FIRST breakpoint depending on how much content you have you will need make your own breakpoint(s) to have your content look nice 
}

* {
	box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
	background-image: linear-gradient(to bottom, $primary-dark 0%, $primary-grey 25%, $primary-lightgrey 50%, $primary-grey 75%, $primary-dark 100%),
}

html {
  scroll-behavior: smooth;
}

/*#endregion Global Variables*/

/*#region Layout Structure*/

.col-half {width: 4.165% }
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
}

.view-container {
	width: 100%;
}

.contact-container {
	width: 75%;
  margin: 10px auto 10px auto;
  text-align: center;
	display: flex;
}

@media (max-width: 415px) {
	.contact-container {
		width: 100%;
	}
}

/*#endregion Layout Structure*/

/*#region Main Title*/

.view-container {
	width: 100%;
}

.title-container {
  width: 100%;
	max-width: 960px;
	margin: 1vh auto 0vh auto;
}

#title-wrap {
	width: 100%;
	margin-bottom: 1em;
  text-align: center;
}

#justice-scale {
	all: unset;
	width: 100px;
	display: inline-block;
	vertical-align: middle;
}

#main-title {
	all: unset;
	font-family: $snell;
	font-size: 8em;
	color: $primary-red;
	-webkit-text-fill-color: darkred;
	display: inline-block;
	vertical-align: middle;
}

@media ( max-width: 414px ) {
	#title-wrap {
		all: unset;
		width: 100%;
		max-width: 960px;
		text-align: center;
	}
	#justice-scale {
		width: 0em;
	}
	#main-title {
		all: unset;
		font-family: $snell;
		font-size: 7em;
		color: $primary-red;
	}
}

/*#endregion Main Title*/

/*#region Navbar*/

.nav-container {
	width: 960px;
  margin: 0 auto;
  color: white;
  overflow-x: hidden;
}

.ag-nav-bar {
	width: 960px;
  margin: 0px auto;
} 

.nav-links {
	font-size: 1.2em;
	padding: 0;
	margin: 0;
  display: flex;
  justify-content: space-around;
  background-color: #0d0d0d;
}

.nav-link-a {
	color: $primary-red;
}

.nav-link-li {
	list-style: none;
	padding: 10px;
}

.nav-link-a {
	color: $primary-light;
	font-family: copperplate;
}

.nav-link-a:hover {
	color: $primary-red;
	font-style: italic;
	cursor: pointer;
}

.ag-container {
	width: 100%; 
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
	overflow-x:hidden;
}

.nav {
  width: 35vw;
  position: fixed;
  z-index: 999;
  background-color: $primary-grey;
  border-radius: 2%;
  display: none;
}

.nav.show {
  right: 0;
  top: 60px;
  animation: fadeIn 0.5s;
  display: block;
}

.burger-button {
  display: inline-block;
  position: fixed ; 
  top: 5px;
  right: 7px ;
  z-index: 99;
  font-size: 36px;
  cursor: pointer;
  padding: 9px 7px;
  background-color: $primary-lightgrey;
  border-radius: 9px;
  color:$primary-light;
}

.close-menu {
  font-family: Copperplate;
  text-decoration: none;
  color: white;
  padding: 16px 10px;
  padding-left: 40px;
  font-size: 24px;
  cursor: pointer;
}

.close-menu:hover {
  color: white;
  background-color: $primary-red;
}

.nav-items {
  padding: 0;
  margin: 0;
}

.nav-list {
  list-style: none;
}

.menu-link {
  font-family: $header;
  text-decoration: none;
  color: $primary-light;
  display: flex;
  justify-content: center;
  padding: 16px 10px;
  font-size: 24px;
}

.menu-link:hover {
  color: $primary-light;
  background-color: $primary-red;
}

.nav-container{
  display: none;
}

@media screen and ( max-width: 700px ){
  .nav {
  width: 45vw;
  }
}
    
@media screen and (min-width: 990px){
    .ag-container{
    display: none;
  }

.nav-container {
    display: block;
  }
} 

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*#endregion Navbar*/

/*#region Home Page*/

#home-container {
	width: 100%;
	max-width: 960px;
	margin: 1vh auto 0vh auto;
}

#prof-pic-row {
	display: flex;
	vertical-align: top;
}

#about-me {
	color: $primary-dark;
	font-family: $header;
	font-size: 16px;
	text-align: center;
}

#about-me-list {
	color: $primary-dark;
	font-size: 12px;
	margin: 6px 0px 0px 10px;
}

#main-caption {
	color: $primary-dark;
	font-family: $primary;
	width: 90%;
}

#prof-pic-wrapper {
	width: 100%;
	display: flex;
	overflow: auto;
	vertical-align: top;
}

#about-wrapper {
	width: 54%;
	float: left;
}

#about-header-box {
	height: 2.25em;
	width: 100%;
	background-color: $primary-red;
	float: left;
}

#about-header {
	color: $primary-light;
	font-family: $header;
	font-size: 1.75em;
	text-align: center;
	margin-top: 0.2em;
}

#about-list-box {
	height: 50em;
	width: 100%;
	margin-top: 0.4em;;
	background-color: $primary-light;
	border: 1px solid $primary-lightgrey;
	float: left;
}

#about-list {
	margin-top: 0.5em;
	color: $primary-dark;
	font-size: 1.5em;
	font-family: $primary;
	margin-left: 3.5%;
}

.about-item {
	margin-top: 1em;
}

#caption {
	width: 93%;
	color: $primary-dark;
	font-family: $primary;
	margin-top: 3em;
	margin-left: 3.5%;
}

#personal {
	width: 93%;
	color: $primary-dark;
	font-family: $primary;
	margin-top: 4em;
	margin-left: 3.5%;
}

#cheryl-main {
	all: unset;
	width: 45.5%;
	height: 843px;
	float: right;
	margin-left: 0.75%;
}

#testimonials-wrap {
	width: 100%;
}

#testHeader-box {
	height: 2.2em;
	margin-top: 1vh;
	text-align: center;
	color: $primary-light;
	background-color: $primary-red;
}

#testHeader {
	font-family: $header;
	font-size: 2em;
	margin: 0.1em auto 0em auto;
}

#testimonials-box {
	margin-top: 1vh;
	background-color: $primary-light;
	padding: 2vh;
}

.testimonial-col {
	width: 100%;
	display: inline-block;
}

.testimonial {
	font-family: $primary;
	font-size: .85em;
  color: $primary-dark;
	margin-bottom: 10px;
}

.testBy {
	font-style: italic;
	margin-bottom: 15px;
	margin-left: 50%;
}

.test-link {
	color: $primary-red;
	font-style: bold;
}

@media ( max-width: 975px ) {
  #prof-pic-wrapper {
		width: 100%;
    display: table;
	}
	
	#about-wrapper {
		all: unset;
		width: 100%;
		display: table;
	}

	#cheryl-main {
		all: unset;
		width: 100%;
		display: table;
		margin-top: 8px;
	}
}

@media ( max-width: 414px ) {
  #prof-pic-wrapper {
		width: 100%;
    display: table;
	}
	
	#about-wrapper {
		all: unset;
		width: 100%;
		display: table;
	}

	#cheryl-main {
		all: unset;
		width: 100%;
		display: table;
		margin-top: 8px;
	}

	#caption {
		margin-top: 1.5em;
	}
	
	#personal {
		margin-top: 2em;
	}
	
	#testimony-box {
		display: flex;
		flex-flow: column wrap;
	}

	.testimony-comments {
		width: 95%;
		margin: 9px;
	}
}

/*#endregion Home Page*/

/*#region Novel*/

.N-Hero {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 11em;
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85),rgba(45, 41, 41, 0.71)), 
  url("https://www.lawliberty.org/wp-content/uploads/2017/07/AdobeStock_140260471.jpeg");
}

#N-title {
  font-size: 4em;
  margin: auto 5em auto 2em;
  text-align: left;
  font-family: $snell;
  color: #d1d0d0;
}

#BV-info {
	font-family: $primary;
}

.para-heading {
	font-weight: bold;
	margin-bottom: 5px;
	text-align: center;
}

.italic-head {
  color: $primary-red;
	font-style: italic;
}

#novel-cover {
  width: 264px;
  margin: 2em;
  order: 1;
  float: right;
  align-self: center;
}

@media (max-width: 710px) {
	#N-title{
		font-size: 3.5em;
    margin: auto;
    font-family: Snell Roundhand,Cursive;
    color: #d1d0d0;
	}
}

@media (max-width: 415px) {
	#N-title{
		font-size: 4em;
    margin: auto;
    font-family: Snell Roundhand,Cursive;
    color: #d1d0d0;
	}

	.N-Hero {
    display: flex;
    flex-flow: column wrap;
		width: 100%;
		padding: 0;
    height: 11em;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: -1;
    background-image: linear-gradient(rgba(0,0,0,.85),rgba(45,41,41,.71)),url(https://www.lawliberty.org/wp-content/uploads/2017/07/AdobeStock_140260471.jpeg);
		}

	#novel-cover {
		width: 264px;
		margin: 2em 2em 2em 2em;
		order: 1;
		float: none;
		align-self: center;
	}
}

/*#endregion Novel*/

/*#region Bay Vista Page*/

.BV-Hero {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
  background-image: linear-gradient(rgba(49, 47, 47, 0.5), rgba(66, 64, 64, 0.5)), 
  url("https://i1.wp.com/bvista.org/wp-content/uploads/2019/04/banner.png?fit=1568%2C830&amp;ssl=1");
}

#BVPageLogo {
  width: 60%;
}

#BV-quote{
  color: #f2f2f2;
  font-family: Verdana,Geneva,Tahoma,sans-serif;
  margin-top: 45px;
  font-size: 70%;
}

#BV-row{
  margin: 0;
  padding: 0;
}

#BV-textbox{
  height: 100%;
  width: 99%;
  background-color: #f2f2f2;
  border: 1px solid #4d4d4d;
  text-align:center;
}

#BV-info {
  color: #0d0d0d;
  margin: 2em;
  order: 2;
  font-size: 17px;
  text-align: left;
}

#cheryl-BV {
  width: 264px;
  margin: 2em 2em 2em 2em;
  order: 1;
  float: right;
  align-self: center;
}

.BV-heading {
	font-weight: bold;
	margin-bottom: 5px;
}

.BV-link{
  color: $primary-red;
	font-style: italic;
}

.BV-link:hover {
	cursor: pointer;
}

@media screen and (max-width: 415px) {
  #cheryl-BV{
    float:none;
  }
}

#red-divider {
	height: 30px;
	width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
	background-color: $primary-red;
}

/*#endregion Bay Vista Page */

/*#region Affiliate*/

.global-container {
	width: 100%;
	max-width: 960px;
  margin: 1em auto;
  text-align: center;
  overflow-x: hidden;
}

.intro-title {
  color: $primary-red;
  text-align: center;
  font-size: 3em;
  font-family: Copperplate;
}

.header-p {
  color: $primary-light;
  margin-top: 1.1em;
  padding: 0px 30px;
}

@media screen and (max-width: 900px) {
  .header-p {
    display: none;
  }
}

.prof-pic {
    background-image: url('./images/mallet.jpeg');
    // background-image: url('')
    height: 20vh;
    background-size: cover;
    background-position: top;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
}

.comm-pic {
  background-image: url('./images/community.jpeg');
  // background-image: url('')
  height: 20vh;
  background-size: cover;
  background-position: center;
  opacity: 1;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
}

.header-image {
	text-align: center;
}

@media screen and (max-width:900px) {
	.div-to-move-a-tag {
    padding-top: 50px;
}
}

@media screen and (min-width: 900px) {
  .div-to-move-a-tag {
    padding-top: 0px;
    margin-top: 56px;
  }
	
  .header-image {
		display: flex;
    text-align: center;
  }
	
  .prof-pic {
		background-image: url('./images/mallet.jpeg');
		background-size: cover;
		background-position: center;
		background-size: cover;
    background-position: 50%;
    width: 50%;
    height: 50vh;
	}

  .comm-pic {
		background-image: url('./images/community.jpeg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-size: cover;
		background-position: 50%;
		width: 50%;
		height: 50vh;
	}
}

#logo {
	width: 13em;
	height: 12em;
	box-shadow: 1px 0px 19px 1px black;
}

#professional:hover, #community:hover {
  transition: color 0.5s;
  color: $primary-red;
}

#professional {
  font-family: Copperplate;
  font-size: 2.7em;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

#community {
  font-family: Copperplate;
  font-size: 2.9em;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

#comm {
  font-family: Copperplate;
  color: $primary-light;
	font-size: 2.6em;
	background-color: $primary-red;
}

#prof {
	font-family: Copperplate;
	background-color: $primary-red;
	font-size: 2.6em;
	color: $primary-light;
}

.years-header {
  text-align: center;
  font-size: 1.8em;
  color: $primary-red;
}

.org-name {
  font-size: 1.5em;
  padding-top: 12px;
  padding-bottom: 29px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(197, 157, 88);
}

.org-name-pushed-left {
  font-size: 1.5em;
  padding-top: 12px;
  padding-bottom: 29px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(197, 157, 88);
}

@media screen and (min-width: 900px){
  .org-name {
    font-size: 1.5em;
    padding-top: 12px;
    padding-bottom: 29px;
    text-align: center;
    padding-left: 10px;
    color: rgb(197, 157, 88);
  }

  .org-name-pushed-left {
    font-size: 1.5em;
    padding-top: 12px;
    padding-bottom: 29px;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
    color: rgb(197, 157, 88);
  }
}

.prof-org-name {
  font-size: 1.5em;
  padding-top: 60px;
  padding-bottom: 29px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(197, 157, 88);
}

.prof-org-name-pushed-left {
  font-size: 1.5em;
  padding-top: 60px;
  padding-bottom: 29px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(197, 157, 88);
}

@media screen and (min-width: 900px){
  .prof-org-name {
    font-size: 1.5em;
    padding-top: 60px;
    padding-bottom: 29px;
    padding-left: 35px;
    text-align: left;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(197, 157, 88);
  }

  .prof-org-name-pushed-left {
    font-size: 1.5em;
    padding-top: 60px;
    padding-bottom: 29px;
    padding-right: 35px;
    text-align: left;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(197, 157, 88);
  }

  .flex-after  {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .flex-div  {
  text-align: right;
  }

  .flex-invert {
    display: flex;
    justify-content: space-between;
  }

  .flex-div-invert {
  text-align: left
  }
}

/*#endregion Affiliate*/

/*#region Credentials Component*/

.overallContainer {
  width: 100%;
}

.customBox {
	margin: 0.5em auto 0em auto;
	max-width: 960px;
	width: 100%;
	display: flex;
	flex-flow: column wrap;
}

.pageTitle {
	text-align: center;
	background-color: $primary-red;
	color: $primary-light;
	font-family: $header;
	font-size: 2.5em;
	margin: 2vh 0vh 2vh 0vh;
	width: 100%;
}

.rowLight {
	background-color: $primary-light;
	width: 100%;
}

.staticButton {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2em;
  text-align: center;
  text-decoration: underline;
	border: none;
	font-style: italic;
  color: $primary-red;
  background: none!important;
  cursor: pointer;
  float: right;
  margin: 1em 3em 1em 1em;
  padding: 0!important
}

.read-more {
	font-family: $primary;
	color: $primary-dark;
}

.header2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.1em;
    margin: 1em 1.5em 0.6em 1.5em;
    padding: .5em;
    color: $primary-dark;
}

.header4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.8em;
    margin: 2em 0em 4.2em 0em;
    text-align: center;
    color: $primary-dark;
}

.header4-2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 0.8em;
  margin: 2em 0em 4.2em 0em;
  text-align: center;
  color: $primary-dark;
}

.moreInfoVisible {
    margin: 2em;
    padding: 2em;
    background-color: $primary-light;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: block;
    color: $primary-grey
}

.moreInfoHidden {
    margin: 2em;
    padding: 2em;
    background-color: $primary-light;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: none;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
    padding: .5em;
}

.list  {
    padding-left: 0em;   
    list-style-position: inside;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1em;
}

.listItem {
    color: $primary-dark;
    padding: 0.3em;
    margin: 0.3em 0em 0.3em 9em
}

.blockPadding {
    color: $primary-grey;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

@media screen and (max-width: 415px) {
  .overallContainer {
    text-align: center;
    font-size: small;
  }

  .pageTitle {
    font-size: x-large;
  }

  .listItem {
    font-size: 0.1em;
    text-align: left;
    text-justify: inter-word;
    margin: 0.3em 0.3em 0.3em 2em;
  }

  .staticButton {
    all: unset;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1em;
    text-align: center;
    text-decoration: underline;
    color: rgb(197, 157, 88);;
    background: none!important;
    margin: 1.5em;
    cursor: pointer;
    border: none;
    padding: 0!important
  }

  .header4, .header4-2 {
    font-size: 0.1em;
  }

  .pageTitle {
    font-size: x-large;
  }

  .blockPadding {
    font-size: .9em;
  }

  .moreInfoVisible {
    margin: 2em;
    padding: 2em;
    background-color: $primary-light;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: block;
    color: $primary-grey;
    text-align: left;
    text-justify: inter-word;
  }
}

/*#endregion Credentials Component*/

/*#region Achievements Component*/

.block {
  background-color: $primary-light;
  padding: 1.3em;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: $primary-grey;
}

.blockImage {
	margin:1em;
  float: left;
  width: 35%;
  display: flex;
}

.blockImage2 {
  margin: 1em;
  float: right;
  width: 35%;
  display: flex;
}

.block2 {
  padding: 2em;
  text-align: right;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: $primary-grey;
}

.block2Alternate {
  padding: 2em;
  text-align: left;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: $primary-grey;
}

.publication-link img:hover {
	box-shadow:2px 2px 5px $primary-grey;
}

@media screen and (max-width: 415px) {
  .block2, .block2Alternate {
    text-align: center;
  }

  .blockImage, .blockImage2 {
  margin-left: auto;
  margin-right: auto;
  float: none;
  width: 65%
  }
}

/*#endregion Achievements Component*/

/*#region Footer Component */

#footer-container {
	width: 100%;
	max-width: 1444px;
	margin: 3vh auto;
}

#networks-wrap {
	float: left;
	width: 30%;
}

#networks {
	margin-top: auto;
	margin-bottom: auto;
}

#BV {
	width: 138px;
	height: 20px;
	margin-left: 20%;
	display: inline-flex;
	vertical-align: middle;
}

#Synergy {
  width: 85px;
  height: 55px;
	margin-left: 13%;
	display: inline-flex;
	vertical-align: middle;
}

#name-wrap {
	float: left;
	width: 36.6%;
	margin-top: 1em;
	text-align: center;
}

#name {
	width: 100%;
	margin: auto;
}

.quote {
	font-family: 'Copperplate';
	font-size: 1.2em;
	color: white;
}

#contact-wrap {
	width: 33.3%;
	float: right;
}

.contact-info {
	color: $primary-light;
	font-size: 0.5em;
	display: inline-flex;
	vertical-align: middle;
}

#address {
	margin-left: 20px;
}

#LinkedIn {
  width: 25px;
	height: 25px;
	margin-left: 20px;
	display: inline-flex;
	vertical-align: middle;
}

.glpyh-link img:hover{
  box-shadow: 2px 2px 5px $primary-red;
}

@media screen and (max-width: 414px) {
	#networks-wrap {
		width: 400px;
		margin: auto;
	}

	#networks {
		display: flex;
    flex-direction: row;
    justify-content: space-around;
	}
	
	#BV {
		margin-left: 25px;
		display: flex;
    flex-direction: column;
		vertical-align: middle;
	}
	
	#Synergy {
		margin-left: 25px;
		display: flex;
    flex-direction: column;
		vertical-align: middle;
	}

	#name-wrap {
		width: 414px;
		margin-top: 0.75em;
		text-align: center;
	}

	.quote {
		font-size: 1em;
	}

	#contact-wrap {
		width: 414px;
		margin-top: 15px;
		text-align: center;
	}
}

@media screen and (max-width: 980px) {  
	#networks-wrap {
		width: 414px;
	}

	#networks {
		margin: 0px auto;
	}

	#BV {
		margin-left: 32%;
	}
	
	#Synergy {
		margin-left: 9%;
	}

	#name-wrap {
		width: 414px;
		margin-top: 0.75em;
		text-align: center;
	}

	#contact-wrap {
		width: 414px;
		margin-top: 15px;
		text-align: center;
	}
}

@media screen and (max-width: 1180px) {
	#networks-wrap {
		order: 2 !important;
		width: 100%;
	}

	#networks {
		margin: 0px auto;
	}

	#BV {
		margin-left: 32%;
	}
	
	#Synergy {
		margin-left: 9%;
	}

	#name-wrap {
		order: 1 !important;
		width: 100%;
		margin-top: 0.75em;
		text-align: center;
	}

	#contact-wrap {
		order: 3 !important;
		width: 100%;
		margin-top: 15px;
		text-align: center;
	}
}

/*#endregion Footer*/
